import React, { useState } from "react"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { Background, Parallax } from "react-parallax"
import { Grid, Row, Col } from 'react-bootstrap'
import { graphql } from "gatsby"
import StrataDocumentsModal from "../documents/strata-documents-modal"
import TitleHeader from "../../components/title-header"

const DeveloperServices = () => {
  const [currentForm, setCurrentForm] = useState("");
  const developerServicesForm = 'developerServices'
  const developerServicesUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/17'
  return (
    <Layout>
      <div>
        <SEO title="Company | Korecki Real Estate Services"
             description="We are licensed through the Real Estate Council of B.C., and believe in providing personalized service, ongoing communication and maintaining strong working relationships with our clients"
             keywords="korecki,Real Estate,Strata Management services, Alex Korecki, Vesna Korecki, licensed , Client Property Account System"/>
        <TitleHeader title={"Developer Services"}/>
        {/*The following code is not from contentful*/}
        <Col md={12} className="blocktext" style={{ backgroundColor: 'rgba(250,250,250)'}}>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <p className="no-h middle justified-black">Your reputation is everything.
                We understand that a seamless transition from Developer to Strata Corporation
                creates and maintains a positive Developer image, which informs your future projects
                and drives sales.
                <br/>
                <br/>
                With a diverse portfolio under management, ranging from a small-town home building,
                to 400+ unit mixed-use/air space parcel developments, we can assist with
                establishing a site of any size or complexity. </p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h1 className="text-uppercase font-weight-bold small-h1 middle">CONSULTATION & BUDGET PREPERATION </h1>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle justified-black">We work with your team to understand your
                project goals and objectives. The information is then used to help formulate an operating
                budget capable of sustaining your vision.
                <br/>
                <br/>
                We understand that a competitive budget is essential for driving sales, and actively work
                with you and your marketing team to create an operating budget that meets your operational
                and marketing goals.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h1 className="text-uppercase font-weight-bold small-h1 middle">BUILDING STARTUP</h1>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle justified-black">We understand that quality site staff
                and vendors are integral to successful building operations. We exclusively utilize vendors
                with a proven new building startup record. This process facilitates a smooth move-in/key
                handover and plays an integral factor in interim budget cost controls.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h1 className="text-uppercase font-weight-bold small-h1 middle">BUILDING TRANSITION & DEFICIENCIES</h1>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle justified-black">We work with your team to facilitate beneficial
                possessions dates and expedited first annual general meetings, thereby limiting expenses and
                reducing interim budget exposures.
                <br/>
                <br/>
                We actively educate new owners and council members, helping them understand each parties’
                responsibilities which helps foster a positive and collaborative work relationship.
                The process helps drive a positive deficiency resolution process as new owners are better
                aware of HPA Limits and Exclusions.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <p className="no-h middle justified-black">As a locally owned and operated Strata and
                Rental Management company we understand the intricacies associated with developing Rental,
                Strata and/or mixed-use developments. Throughout the entire development process our senior
                team is at your disposal to provide guidance and/or last minutes adjustments/revisions.
                <br/>
                <br/>
                Contact us today for a no obligations development services assessment and no obligations
                management proposal.</p>
            </Row>
          </section>
          <section className="last-section background ">
            <div className="row center">
              <Row className="pt-4">
                <button
                  className="btn btn-outline btn-primary-1 text-uppercase parallax-button"
                  onClick={() => setCurrentForm(developerServicesForm)}>
                  Developer Services Proposal Request
                  <StrataDocumentsModal
                    open={currentForm===developerServicesForm}
                    openModal={setCurrentForm}
                    documentUrl={developerServicesUrl}/>
                </button>
              </Row>
            </div>
          </section>
        </Col>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulDevelopers {
      title
      description {
        json
      }
      image {
        file {
          url
        }
      }
    }
  }`
export default DeveloperServices;
